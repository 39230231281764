export const apiEndpoints = {
    AUTH_SOCIAL: '/authservices/auth',
    AUTH_GOOGLE: '/authservices/auth/google',
    AUTH_LINKEDIN: '/authservices/auth/linkedin',
    AUTH_MICROSOFT: '/authservices/auth/microsoft',
    AUTH_SLACK: '/authservices/auth/slack',
    AUTH_SIGNUP: '/authservices/auth/signup',
    EMAIL_PASSCODE: '/authservices/accounts/me/email/passcode',
    AUTH_LOGIN: '/authservices/auth/login',
    MFA_LOGIN: '/authservices/auth/mfa/verify',
    MFA_LOGIN_RECOVERY: '/authservices/auth/mfa/recovery',
    ACCOUNTS_ME: '/authservices/accounts/me',
    INVITE: '/authservices/invite',
    NOTIFICATIONS: '/authservices/notifications',
    NOTIFICATIONS_COUNT: '/authservices/notifications/counts',
    REFRESH: '/authservices/auth/token/refresh',
    EMAIL_VERIFY: '/authservices/accounts/me/email/verify',
    PASSWORD_FORGET: '/authservices/accounts/password/forget',
    PASSWORD_VALIDATE: '/authservices/accounts/password/validate',
    PASSWORD_RESET: '/authservices/accounts/password/reset',
    DEACTIVATE: '/authservices/accounts/me/deactivate',
    REACTIVATE: '/authservices/accounts/me/reactivate',
    UPDATE_PASSWORD: '/authservices/accounts/password',
    BILLING: '/authservices/accounts/me/addresses/me/billing',
    PHONE: '/authservices/phone/validate',
    USER_FEEDBACK: '/authservices/feedback',
    POP_UPS: '/authservices/popups',
    CANNY_POST_CREATE: '/authservices/canny/posts/create',
    CANNY_CATEGORIES_LIST: '/authservices/canny/categories/list',
    CANNY_USERS_RETRIVE: '/authservices/canny/users/retrieve',

    MFA_SETUP: '/authservices/accounts/me/mfa/setup',
    MFA_VERIFY: '/authservices/accounts/me/mfa/verify',
    MFA_BACKUP: '/authservices/accounts/me/mfa/backup',
    MFA_DISABLE: '/authservices/accounts/me/mfa/disable',


    DASHBOARDS_ME: '/dashboardservices/dashboards/me',
    DASHBOARD_SERVICES_TRASH: '/dashboardservices/trash',
    ADMIN_TEMPLATE: '/dashboardservices/admin/templates',
    ADMIN_TEMPLATE_ME: '/dashboardservices/admin/templates/me',
    CATEGORIES: '/dashboardservices/categories',
    TEMPLATES: '/dashboardservices/dashboards',
    THEMES: '/dashboardservices/themes',
    GLOBAL_SEARCH: '/dashboardservices/search',
    ADMIN_CATEGORIES: '/dashboardservices/admin/categories',
    ADMIN_THEMES: '/dashboardservices/admin/themes',
    FAVORITE_ELEMENT: '/dashboardservices/favourites/elements',
    ELEMENT_CATEGORIES: '/dashboardservices/elements/categories',
    ELEMENTS: '/dashboardservices/elements',
    DASHBOARD_EMBED: '/dashboardservices/embed',
    ADMIN_DASHBOARD_EMBED: '/dashboardservices/templates/embed',
    FEATURES: '/dashboardservices/me/features',
    RESOURCES_ADMIN: '/dashboardservices/admin/templates/resources',
    RESOURCES_DASHBOARD: '/dashboardservices/dashboards/resources',
    EXPORT_TASK: '/dashboardservices/task',

    SUBSCRIPTION: '/paymentservices/subscriptions/me',
    UPGRADE_PLANS: '/paymentservices/subscriptions/me/upgrade',
    PLANS: '/paymentservices/plans',
    CHECKOUT: '/paymentservices/checkout',
    CANCEL_PLAN: '/paymentservices/subscriptions/me/cancel',
    CHANGE_PAYMENT: '/paymentservices/subscriptions/me/payment-method',
    CHANGE_CYCLE: '/paymentservices/subscriptions/me/billing-cycle',
    REVERT_CANCEL_SUBSCRIPTION: '/paymentservices/subscriptions/me/cancel/revert',
    REVERT_CANCEL_CYCLE: '/paymentservices/subscriptions/me/billing-cycle/revert',
    REVERT_SUBSCRIPTION_MODIFICATIONS: '/subscriptions/revert',
    INVOICES: '/paymentservices/invoices/me',
    ACTIVE_CARD: '/paymentservices/subscriptions/me/payment-method',
    PRO_TEMPLATES: '/paymentservices/plans?feature=templates-per-category&is_default=true',
    FREE_THEMES: '/paymentservices/plans?feature=global-themes&is_default=true',

    BLOGS: '/blogservices/posts',
    ADMIN_BLOGS: '/blogservices/admin/posts',

    RESOURCES_LIST: '/blogservices/content/resources',
    RESOURCES_CATEGORIES: '/blogservices/content/categories',
    RESOURCES_TYPES: '/blogservices/content/types',

    CHATBOT_CONVERSATIONS: '/authservices/chatbot/conversations',
    OG_META: '/blogservices/og-meta',

    REQUEST_TEMPLATE: '/dashboardservices/template/requested',
    GEODATA: '/dashboardservices/admin/geodata',

    DATA_DICT: '/blogservices/dataprocessing/parse/dashboard',
    FAVORITE: '/dashboardservices/favourites/me',

    //Comments
    SHARED_PROJECTS: '/dashboardservices/projects/me/shared',
    SHARED_PROJECT_LIST_FILTERS: '/dashboardservices/projects/me/shared/filters',
    SPECIFIC_PROJECT: '/dashboardservices/projects',


    PROJECT_IMAGE_SAVE: '/dashboardservices/admin/projects/renderer/',
    TEMPLATE_IMAGE_SAVE: '/dashboardservices/admin/templates/renderer/',

    //lms
    CREATE_WORKSPACE: '/authservices/me/workspaces',
    LIST_WORKSPACES: '/authservices/me/workspaces?per_page_items=999',

    WORKSPACE_ME: '/authservices/me/workspaces',
    WORKSPACE_DETAILS: '/authservices/workspaces',



    COUPON_VALIDATE: '/paymentservices/checkout/promocode/validate',

    CREATE_REQUESTS: '/authservices/me/workspaces',
    LIST_REQUESTS: '/authservices/me/workspaces',

    LIST_ACCOUNT: '/authservices/me/workspaces',

    MEMBER_WORKSPACE_SUBSCRIPTION: '/authservices/workspaces',
    COUPON_VALIDATE : '/paymentservices/checkout/promocode/validate',

    WALLET_STATS : '/authservices/wallets',
    WALLET_STATEMENTS : '/authservices/wallets/statements',
    WALLET_USE : '/authservices/wallets/utilize',
    WALLET_SOURCE : '/authservices/me/workspaces/wallet-source',
    WALLET_PLANS : '/authservices/wallets/plans',
    WALLET_PURCHASE_PREVIEW : '/authservices/wallets/payments/preview',
    WALLET_CHECKOUT : '/authservices/wallets/payments/checkout',    
    WALLET_STATEMENTS : '/authservices/wallets/statements'
};