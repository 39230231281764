import React, { Suspense, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import './App.css';
import { ToastProvider } from 'react-toast-notifications';
import { socketURL } from './utils/helperFunctions/envLinks';
import Auth from '../src/utils/authGuard'
import AuthenticationMFA from '../src/utils/mfaGaurd'
import UserSession from './helperComponents/userSession';
import Chatbot from './views/chatbot/chatbot';
import Fallback from './helperComponents/fallback';
import { Provider } from 'react-redux';
import { persistor, store } from './redux-toolkit/store';
import '@xyflow/react/dist/style.css';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactFlowProvider } from '@xyflow/react';
import { APP_VERSION } from './utils/constants/globalConstants';
import AcceptTeamInvite from './views/workspace/acceptInvite';

// const lazyRetry = function (componentImport) {
//   return new Promise((resolve, reject) => {
//     const hasRefreshed = JSON.parse(
//       window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
//     );
//     componentImport().then((component) => {
//       window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
//       resolve(component);
//     }).catch((error) => {
//       if (!hasRefreshed) {
//         window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
//         return window.location.reload();
//       }
//       reject(error);
//     });
//   });
// };

const importWithRetry = (importFn, maxRetries = 3, delay = 1000) => {
  let retries = 0;
  
  const retry = () => {
    return importFn().catch((error) => {
      if (retries >= maxRetries) throw error;
      
      retries++;
      return new Promise(resolve => {
        console.log(`Retrying chunk load (${retries}/${maxRetries})...`);
        setTimeout(() => resolve(retry()), delay);
      });
    });
  };
  
  return retry();
};

const Login = React.lazy(() => importWithRetry(() => import('./views/login/login')))
const Signup = React.lazy(() => importWithRetry(() => import('./views/signup/signup')))
const ForgotPassword = React.lazy(() => importWithRetry(() => import('./views/forgotPassword/forgotPassword')))
const ChangeEmail = React.lazy(() => importWithRetry(() => import('./views/changeEmail/changeEmail')))
const OTP = React.lazy(() => importWithRetry(() => import('./views/forgotPassword/enterCode')))
const UpdatePassword = React.lazy(() => importWithRetry(() => import('./views/forgotPassword/reEnterPassword')))
const Reactivate = React.lazy(() => importWithRetry(() => import('./views/profile/account/reactivate')))
const Deactivate = React.lazy(() => importWithRetry(() => import('./views/profile/account/successDeactivate')))
const MobileError = React.lazy(() => importWithRetry(() => import('./helperComponents/mobileError')))
const Payment = React.lazy(() => importWithRetry(() => import('./views/payment/individualLicense/checkout')))
const WorkspacePayment = React.lazy(() => importWithRetry(() => import('./views/payment/workspace/workspacePayment')))
const ManageLicense = React.lazy(() => importWithRetry(() => import('./views/payment/manageLicense/manageLicense')))
const TeamInfo = React.lazy(() => importWithRetry(() => import('./views/payment/workspace/teamInfo')))
const Builder = React.lazy(() => importWithRetry(() => import('./views/builder/index')))
const MokkupBuilder = React.lazy(() => importWithRetry(() => import('./views/mokkupBuilder/index')))
const Onboarding = React.lazy(() => importWithRetry(() => import('./views/onboarding/multistep')))
const Invite = React.lazy(() => importWithRetry(() => import('./views/onboarding/step3')))
const VerifyEmail = React.lazy(() => importWithRetry(() => import('./views/onboarding/verifyEmail')))
const Layout = React.lazy(() => importWithRetry(() => import('./views/layout/layout')))
const Templates = React.lazy(() => importWithRetry(() => import('./views/templates/templates')))
const TemplatesInfo = React.lazy(() => importWithRetry(() => import('./views/templates/templateInfo')))
const ErrorPage = React.lazy(() => importWithRetry(() => import('./helperComponents/404')))
const PaymentStatus = React.lazy(() => importWithRetry(() => import('./views/payment/status')))
const Embed = React.lazy(() => importWithRetry(() => import('./views/mokkupBuilder/components/embed/Frame')))
const ImageSave = React.lazy(() => importWithRetry(() => import('./views/imageSave/saveImage')))
const Plans = React.lazy(() => importWithRetry(() => import('./views/onboarding/plans')))
const AcceptInvite = React.lazy(() => importWithRetry(() => import('./views/mokkupBuilder/components/accessModes/AcceptInvite')))
const SharedMode = React.lazy(() => importWithRetry(() => import('./views/mokkupBuilder/components/accessModes/SharedMode')))
const ImageGeneration = React.lazy(() => importWithRetry(() => import('./views/mokkupBuilder/components/imageGeneration')))
const Mfa = React.lazy(() => importWithRetry(() => import('./views/mfaLogin/verifymfa')))
const CreditCheckout = React.lazy(() => importWithRetry(() => import('./views/payment/creditSystem/index')))
const CreditPaymentStatus = React.lazy(() => importWithRetry(() => import('./views/payment/creditSystem/creditStatus')))

function App() {

  const consoleWarn = console.warn;
  const SUPPRESSED_WARNINGS = ['chart', 'parser-blocking'];

  console.warn = function filterWarnings(msg, ...args) {
    if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
      consoleWarn(msg, ...args);
    }
  };

  useEffect(() => {
    // Add chunk error handler
    const handleChunkError = (event) => {
      if (event.reason &&
          (event.reason.toString().includes('ChunkLoadError') ||
           event.reason.toString().includes('Loading chunk') ||
           event.reason.toString().includes('Failed to fetch dynamically imported module'))) {
        console.log('Chunk load error detected, reloading...');
        window.location.reload(true);
      }
    };
    // Add event listener
    window.addEventListener('unhandledrejection', handleChunkError);
    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('unhandledrejection', handleChunkError);
    };
  }, []); // Empty dependency array means this runs once on mount


  return (
    <div className="App">
      <Router>
        <ToastProvider>
          <Suspense fallback={<Fallback />}>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route path="/signup" component={Signup} />
              <Route path="/forgotPassword" component={ForgotPassword} />
              <Route path="/changeEmail" component={Auth(ChangeEmail)} />
              <Route path="/enterOTP" component={OTP} />
              <Route path="/updatePassword" component={UpdatePassword} />
              <Route path="/onboarding" component={Auth(Onboarding)} />
              <Route path="/authentication" component={AuthenticationMFA(Mfa)} />
              <Route path="/profile/reactivate" component={Reactivate} />
              <Route path="/deactivate" component={Auth(Deactivate)} />
              <Route path="/invite" component={Auth(Invite)} />
              <Route path="/verify" component={Auth(VerifyEmail)} />
              {/* <Route path="/builder" component={Auth(Builder)} /> Move this route in redux provider */}
              <Route path={['/share', '/screen', '/shared', '/embed', '/template/embed', '/builder', '/imageGeneration']}>
                <Provider store={store}>
                  <PersistGate loading={null} persistor={persistor}>
                    <ReactFlowProvider>
                      <Suspense fallback={<Fallback />}>
                        <Switch>
                          <Route path="/builder" component={Auth(MokkupBuilder)} />
                          <Route path="/screen/:_id" component={Auth(MokkupBuilder)} />
                          <Route exact path="/share/:_id" component={Auth(SharedMode)} />
                          <Route exact path="/shared/:_slug/join/:_token" component={AcceptInvite} />
                          <Route exact path="/shared/:_slug/join" component={AcceptInvite} />
                          <Route exact path="/shared/:_slug" component={AcceptInvite} />
                          <Route exact path="/embed/:_id" component={Embed} />
                          <Route exact path="/template/embed/:_id" component={Embed} />
                          <Route exact path="/imageGeneration" component={ImageGeneration} />
                          <Redirect to="/" />
                        </Switch>
                      </Suspense>
                    </ReactFlowProvider>
                  </PersistGate>
                </Provider>
              </Route>
              <Route path="/404" component={ErrorPage} />
              <Route path="/templates/:_id/:_name" component={Auth(Templates)} />
              <Route path="/templatesInfo/:_id" component={Auth(TemplatesInfo)} />
              <Route exact path="/mobile" component={MobileError} />
              <Route exact path="/checkout" component={Auth(Payment)} />
              <Route exact path="/credits-checkout" component={Auth(CreditCheckout)} />
              <Route exact path="/credit-payment" component={Auth(CreditPaymentStatus)} />
              <Route exact path="/workspace" component={Auth(WorkspacePayment)} />
              <Route exact path="/managelicense" component={Auth(ManageLicense)} />
              <Route exact path="/teamInfo" component={Auth(TeamInfo)} />
              <Route exact path="/team/:_slug/join/:_token" component={AcceptTeamInvite} />
              <Route exact path="/team/:_slug/join" component={AcceptTeamInvite} />
              <Route exact path="/team/:_slug" component={AcceptTeamInvite} />
              <Route exact path="/payment" component={Auth(PaymentStatus)} />
              <Route exact path="/imagesave" component={ImageSave} />
              <Route exact path="/plans" component={Auth(Plans)} />
              <Route path="/" component={Auth(Layout)} />
            </Switch>
          </Suspense>
        </ToastProvider>
      </Router>
      <UserSession />
    </div>
  );
}

export default App;
