export const licenseManagementConstants = {
    TYPE_GRANT_LICENSE : "Grant License",
    TYPE_INVITE : "Invite",
    TYPE_RENEW_SUBSCRIPTION : "Renew Subscription",
    CANCEL_REASON_OTHER_REASONS : "Other reasons",
    ERROR_TYPE_BOTH : "both",
    ERROR_TYPE_REASON : "reason",
    ERROR_TYPE_AGREE : "agree",
    TYPE_YEARLY : "Yearly",
    TYPE_MONTHLY : "Monthly",
    CARD_TYPE_AMERICAN_EXPRESS : "American Express",
    BILLING_TYPE_TEAMS : "teams",
    SOURCE_TYPE_TEAMS : "teams",
    INVOICE_TYPE_TEAM : "Team",
    INVOICE_TYPE_INDIVIDUAL : "Individual",
    MEMBER_ROLE_TYPE_OWNER : "Owner",
    EVENT_TYPE_CANCEL_SUBSCRIPTION : "cancelSubscription",
    EVENT_TYPE_CANCEL_SUBSCRIPTION_TEAMS : "cancel_subscription_teams",
    FILTER_TYPE_PRO : "pro",
    FILTER_TYPE_FREE : "free",
    INDUSTRY_TYPE_OTHERS : "Others",
    VIEW_TYPE_DESIGN : "designs",
    VIEW_TYPE_SCREEN : "screens",
    VIEW_TYPE_EXPORT : "exports",
    VIEW_TYPE_TEMPLATES : "templates",
    VIEW_TYPE_THEMES : "themes",
    VIEW_TYPE_FRAMESIZE : "framesize",
    LOADER_TYPE_ACCEPT : "accept",
    LOADER_TYPE_REDIRECT : "redirect",
    LOADER_TYPE_LOADING : "loading",
    NOTIFICATION_TYPE_MENTIONED : "Mentioned",
    NOTIFICATION_TYPE_INVITATION : "Invitation",
    TYPE_CREDIT_REQUEST : "Buy Credit",
    TYPE_CREDIT_ACCESS_REQUEST : "Grant Credit Access"
}