export const routesConstants = {
    DASHBOARD: '/dashboard',
    VERIFY: '/verify',
    ONBOARDING: '/onboarding',
    HOME: '/',
    MOBILE: '/mobile',
    LOGIN: '/login',
    FORGOT_PASSWORD:'/forgotPassword',
    ENTER_OTP:'/enterOTP',
    UPDATE_PASSWORD:'/updatePassword',
    DEACTIVATE : '/deactivate',
    BUILDER:'/builder',
    SCREEN:'/screen',
    PROJECTS : '/projects',
    LEARN : '/learn',
    TRASH : '/trash',
    PROFILE: '/profile',
    TEMPLATES : '/templates',
    TEMPLATES_INFO:'/templatesInfo',
    PREVIEW:'/preview',
    BILLING : '/billing',
    CHECKOUT : '/checkout',
    STATUS : '/payment',
    SIGNUP : '/signup',
    REACTIVATE : '/profile/reactivate',
    INVITE : '/invite',
    ERROR : '/404',
    PLANS : '/plans',
    RESOURCES : '/resources',
    SHARED_PROJECTS : '/share',
    TEAM_INFO : '/teamInfo',
    WORKSPACE : '/workspace',
    MANAGE_LICENSE : '/managelicense',
    MFA: '/authentication',
    CREDIT_CHECKOUT : "/credits-checkout"
};