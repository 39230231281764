
import React from "react";
import {
  ShimmerButton,
  ShimmerTitle,
  ShimmerThumbnail,
  ShimmerCategoryItem,
  ShimmerCircularImage,
  ShimmerSimpleGallery,
  ShimmerBadge
} from "react-shimmer-effects";
import { Row, Col, Button, Container } from 'reactstrap';

export default function ShimmerGrid({ view }) {
  const a = ["1", "2", "3", "4", "5"];
  const recentChatList = ["1", "2", "3", "4", "5"]
  return (
    <>
      {view == "yourDesignGrid" ? (<ShimmerSimpleGallery card imageHeight={292} col={2} row={2} caption />)
        : view == "yourDesignList" ? (
          <div><ShimmerTitle className="lazyDesign_tableHeading" line={1} variant="primary" />
            {a && a.map((item, index) => {
              return (
                <div key={index} className="lazyDesign_tableRows">
                  <ShimmerThumbnail className="lazyDesign_tableCol1" height={40} rounded />
                  <ShimmerTitle className="lazyDesign_tableCol2" line={1} variant="primary" />
                  <ShimmerThumbnail className="lazyDesign_tableCol3" height={20} width={20} rounded />
                  <ShimmerTitle className="lazyDesign_tableCol4" line={1} variant="primary" />
                  <ShimmerTitle className="lazyDesign_tableCol5" line={1} variant="primary" />
                  <ShimmerThumbnail className="lazyDesign_tableCol6" height={20} rounded />
                </div>
              );
            })}
          </div>
        ) : view == "trashGrid" ? (
          <ShimmerSimpleGallery className="reducedSizeThumbnail_lazy" card imageHeight={292} row={2} col={2} caption />
        ) : view == "trashList" ? (
          <div><ShimmerTitle className="lazyTrash_tableHeading" line={1} variant="primary" />
            {a && a.map((item, index) => {
              return (
                <div key={index} className="lazyTrash_tableRows">
                  <ShimmerThumbnail className="lazyTrash_tableCol1" height={40} rounded />
                  <ShimmerTitle className="lazyTrash_tableCol2" line={1} variant="primary" />
                  <ShimmerThumbnail className="lazyTrash_tableCol3" height={20} width={20} rounded />
                  <ShimmerThumbnail className="lazyTrash_tableCol4" height={20} width={20} rounded />
                  <ShimmerThumbnail className="lazyTrash_tableCol5" height={20} rounded />
                </div>
              );
            })}
          </div>
        ) : view == "templateGrid" ? (
          <ShimmerSimpleGallery className="reducedSizeThumbnail_lazy" card imageHeight={292} row={2} col={2} caption />
        ) : view == "home-edit-templates-lazy" ? (
          <div className="home-edit-templates-lazy">
            <ShimmerThumbnail height={166} rounded />
            <ShimmerTitle line={1} gap={10} variant="primary" />
          </div>
        ) : view == "resource-grid-lazy" ? (
          <div className="resource-grid-lazy">
            <Row >
              {recentChatList && recentChatList.map((e) => (<>
                <Col md={6} lg={6} xl={6} className="col-xxl-4 mb-4">
                  <ShimmerThumbnail height={230} rounded />
                  <ShimmerButton size="sm" />
                  <ShimmerButton size="md" />
                  <ShimmerButton size="lg" />
                </Col></>))}</Row>
          </div>
        ) : view == "resource-filter-lazy" ? (
          <div className="resource-filter-lazy">
            <ShimmerButton size="sm" />
            <ShimmerButton size="md" />
            <ShimmerButton size="lg" />
          </div>
        ) : view == "templateList" ? (
          <div>
            <ShimmerTitle className="lazyTemplate_tableHeading" line={1} variant="primary" />
            {a &&
              a.map((item, index) => {
                return (
                  <div key={index} className="lazyTemplate_tableRows">
                    <ShimmerThumbnail className="lazyTemplate_tableCol1" height={40} rounded />
                    <ShimmerTitle className="lazyTemplate_tableCol2" line={1} variant="primary" />
                    <ShimmerThumbnail className="lazyTemplate_tableCol3" height={20} width={20} rounded />
                    <ShimmerThumbnail className="lazyTemplate_tableCol4" height={20} width={20} rounded />
                    <ShimmerThumbnail className="lazyTemplate_tableCol5" height={20} rounded />
                  </div>
                );
              })}
          </div>
        ) : view == "categories" ? (
          <ShimmerSimpleGallery className="reducedSizeThumbnail_lazy" card imageHeight={180} col={3} row={2} caption />
        ) : view == "variant-change-single-popUp" ? (
          <ShimmerSimpleGallery className="reducedSizeThumbnail_lazy" card imageHeight={180} col={1} row={3} />
        ) : view == "variant-change-double-popUp" ? (
          <ShimmerSimpleGallery className="reducedSizeThumbnail_lazy" card imageHeight={120} col={2} row={3} />
        ) : view == "variant-title-popUp" ? (
          <ShimmerSimpleGallery className="reducedSizeThumbnail_lazy" card imageHeight={57} col={1} row={3} />
        ) : view == "elements-section-lazy" ? (
          <ShimmerSimpleGallery className="reducedSizeThumbnail_lazy" card imageHeight={90} col={2} row={10} caption={false} />
        ) : view == "themes-section-current" ? (
          <ShimmerSimpleGallery className="reducedSizeThumbnail_lazy" card imageHeight={90} col={1} row={1} caption={false} />
        ) : view == "themes-section-collapse" ? (
          <ShimmerSimpleGallery className="reducedSizeThumbnail_lazy" card imageHeight={90} col={1} row={10} caption={false} />
        ) : view == "blog-overlay-lazy" ? (
          <div className="blog-overlay-lazy-container">
            {/* <div className="blog-overlay-lazy-header">
            <div className="blog-overlay-lazy-header-left">
              <ShimmerThumbnail width={50} rounded />
              <div className="blog-overlay-lazy-header-title-subtitle">
                <ShimmerThumbnail height={20} width={230} rounded />
                <ShimmerThumbnail height={20} width={230} rounded />
              </div>
            </div>
            <div className="blog-overlay-lazy-header-right">
              <ShimmerButton size="lg" />
              <ShimmerButton size="lg" />
            </div>
          </div> */}
            <div className="blog-overlay-lazy-content">
              <ShimmerTitle line={10} variant="secondary" />
            </div>
          </div>
        ) : view == "template-info-lazy" ? (
          <div className="template-info-lazy-container">
            <ShimmerThumbnail width={200} height={20} rounded />
            <div className="template-info-lazy-header">
              <div className="template-info-lazy-header-left">
                <ShimmerThumbnail height={30} width={300} rounded />
                <ShimmerThumbnail height={15} width={200} rounded />
              </div>
              <div className="template-info-lazy-header-right">
                <ShimmerButton size="sm" />
                <ShimmerButton size="sm" />
                <ShimmerButton size="sm" />
              </div>
            </div>
            <ShimmerThumbnail height={450} className="m-0" rounded />
            <ShimmerTitle line={10} variant="secondary" />
          </div>
        ) : view == "chatbot-lazy" ? (
          <div className="chatbot-lazy-container">
            <ShimmerButton size="lg" />
            <ShimmerThumbnail height={60} />
            <ShimmerTitle line={2} variant="secondary" className="title-1" />
            <div className="two-block">
              <ShimmerThumbnail height={100} className="m-0" rounded />
              <ShimmerThumbnail height={100} className="m-0" rounded />
            </div>
            <ShimmerTitle line={2} variant="secondary" className="title-2" />
            <div className="btn-box">
              <ShimmerThumbnail height={35} rounded />
              <ShimmerThumbnail height={35} rounded />
              <ShimmerThumbnail height={35} rounded />
            </div>
            <ShimmerThumbnail height={50} className="last-box" />
          </div>
        ) : view == "chatbot-recent-msg-list-lazy" ? (
          <div className="recent-msg-list-lazy-container">
            {recentChatList && recentChatList.map((e) => (
              <div className="recent-msg-list" key={e}>
                <div className="msg-list">
                  <ShimmerCircularImage size={40} />
                  <ShimmerTitle line={2} gap={4} variant="secondary" className="text" />
                </div>
                <ShimmerThumbnail height={25} width={25} rounded className="right-box" />
              </div>
            ))}
            <div className="load-more-lazy">
              <ShimmerThumbnail height={20} width={150} className="last-box" />
            </div>
          </div>
        ) : view == "chatscreen-body-lazy" ? (
          <div className="chatscreen-body-lazy-container">
            <div className="line"><ShimmerThumbnail height={30} rounded /></div>
            <div className="bot-message-container">
              <ShimmerCircularImage size={30} className="the-bot" />
              <div className="bot-chating">
                <div className=""><ShimmerThumbnail height={56} width={256} rounded /></div>
              </div>
            </div>
            <div className="user-msg-lazy">
              <ShimmerThumbnail height={40} width={256} rounded />
            </div>
            <div className="bot-message-container">
              <ShimmerCircularImage size={30} className="the-bot" />
              <div className="bot-chating">
                <div className=""><ShimmerThumbnail height={56} width={256} rounded /></div>
              </div>
            </div>
            <div className="user-msg-lazy">
              <ShimmerThumbnail height={56} width={256} rounded />
            </div>
            <div className="bot-message-container">
              <ShimmerCircularImage size={30} className="the-bot" />
              <div className="bot-chating">
                <div className=""><ShimmerThumbnail height={56} width={256} rounded /></div>
              </div>
            </div>
          </div>
        ) : view == "pricingHeaderLazy" ? (
          <div className="pricing-header-lazy">
            <ShimmerButton size="lg" />
            <ShimmerButton size="md" />
          </div>
        ) : view == "notification-box-lazy" ? (
          <div className="lazy-notification-main-container">
            <div>
              <div className="lazy-photo-plus-title">
                <ShimmerCircularImage size={34} />
                <div className="lazy-title-container">
                  <div className="lazy-title-set-one">
                    <ShimmerTitle line={1} variant="secondary" className='lazy-title-one' />
                    <ShimmerTitle line={1} variant="secondary" className='lazy-title-two' />
                  </div>
                  <div className="lazy-title-set-two">
                    <ShimmerTitle line={1} variant="secondary" className='lazy-title-three' />
                    <ShimmerTitle line={1} variant="secondary" className='lazy-title-four' />
                  </div>
                </div>
              </div>
              <div className='lazy-thumbnail'>
                <ShimmerThumbnail height={40} rounded />
              </div>
              <div className='lazy-buttons'>
                <ShimmerButton size="sm" />
                <ShimmerButton size="sm" />
              </div>
            </div>
            <div>
              <div className="lazy-photo-plus-title">
                <ShimmerCircularImage size={34} />
                <div className="lazy-title-container">
                  <div className="lazy-title-set-one">
                    <ShimmerTitle line={1} variant="secondary" className='second-lazy-title-one' />
                    <ShimmerTitle line={1} variant="secondary" className='second-lazy-title-two' />
                    <ShimmerTitle line={1} variant="secondary" className='second-lazy-title-five' />
                  </div>
                  <div className="lazy-title-set-two">
                    <ShimmerTitle line={1} variant="secondary" className='second-lazy-title-three' />
                    <ShimmerTitle line={1} variant="secondary" className='second-lazy-title-four' />
                  </div>
                </div>
              </div>
              <div className='lazy-thumbnail'>
                <ShimmerThumbnail height={62} rounded />
              </div>
              <div className='lazy-buttons'>
                <ShimmerButton size="sm" />
              </div>
            </div>
          </div>

        ) : view == "planListLazy" ? (
          <div className="plan-list-lazy">
            <ShimmerButton size="sm" />
            <div className="amount-lazy">
              <ShimmerButton size="sm" />
              <ShimmerButton size="md" />
            </div>
            <ShimmerButton size="md" />
            <ShimmerButton size="lg" />
            <div className="items-container">
              <div className="items-lazy">
                <ShimmerButton size="sm" />
                <ShimmerThumbnail size="md" width={125} />
              </div>
              <div className="items-lazy">
                <ShimmerButton size="sm" />
                <ShimmerThumbnail size="md" width={200} />
              </div>
              <div className="items-lazy">
                <ShimmerButton size="sm" />
                <ShimmerThumbnail size="md" width={150} />
              </div>
              <div className="items-lazy">
                <ShimmerButton size="sm" />
                <ShimmerThumbnail size="md" width={100} />
              </div>
              <div className="items-lazy">
                <ShimmerButton size="sm" />
                <ShimmerThumbnail size="md" width={160} />
              </div>
            </div>
          </div>
        ) : view == "commenting-sidebar-lazy" ? (
          <div className="lazy-commenting-sidebar">
            <div className="lazy-commenting-photo-plus-title">
              <ShimmerCircularImage size={32} />
              <div className="lazy-commenting-title-container">
                <ShimmerTitle line={1} variant="secondary" className='commenting-lazy-title-one' />
                <ShimmerTitle line={1} variant="secondary" className='commenting-lazy-title-two' />
              </div>
            </div>
            <div className='commenting-lazy-thumbnail'>
              <ShimmerThumbnail height={62} rounded />
            </div>
            <div className="commenting-lazy-footer-container">
              <div className='commenting-lazy-multi-profiles'>
                <ShimmerCircularImage size={24} />
                <ShimmerCircularImage size={24} className="multi-profile-two" />
                <ShimmerCircularImage size={24} className="multi-profile-three" />
                <ShimmerCircularImage size={24} className="multi-profile-four" />
              </div>
              <ShimmerTitle line={1} variant="secondary" className='commenting-lazy-footer-title' />
            </div>
            <div className="lazy-commenting-photo-plus-title">
              <ShimmerCircularImage size={32} />
              <div className="lazy-commenting-title-container">
                <ShimmerTitle line={1} variant="secondary" className='commenting-lazy-title-one' />
                <ShimmerTitle line={1} variant="secondary" className='commenting-lazy-title-two' />
              </div>
            </div>
            <div className='commenting-lazy-thumbnail'>
              <ShimmerThumbnail height={62} rounded />
            </div>
            <div className="commenting-lazy-footer-container">
              <div className='commenting-lazy-multi-profiles'>
                <ShimmerCircularImage size={24} />
                <ShimmerCircularImage size={24} className="multi-profile-two" />
                <ShimmerCircularImage size={24} className="multi-profile-three" />
                <ShimmerCircularImage size={24} className="multi-profile-four" />
              </div>
              <ShimmerTitle line={1} variant="secondary" className='commenting-lazy-footer-title' />
            </div>
          </div>
        ) : view == "lazy-users-listing-box" ? (
          <div className="lazy-users-listing-box-main-container">
            <div className="lazy-users-listing-box-container">
              <div className="lazy-users-listing-box-photo-plus-title">
                <ShimmerCircularImage size={36} />
                <div className="lazy-users-listing-box-title-container">
                  <ShimmerTitle line={1} variant="secondary" className='users-listing-box-lazy-title-one' />
                  <ShimmerTitle line={1} variant="secondary" className='users-listing-box-lazy-title-two' />
                </div>
              </div>
              <div className='users-listing-box-lazy-buttons'>
                <ShimmerButton size="md" />
              </div>
            </div>
            <div className="lazy-users-listing-box-container">
              <div className="lazy-users-listing-box-photo-plus-title">
                <ShimmerCircularImage size={36} />
                <div className="lazy-users-listing-box-title-container">
                  <ShimmerTitle line={1} variant="secondary" className='users-listing-box-lazy-title-one' />
                  <ShimmerTitle line={1} variant="secondary" className='users-listing-box-lazy-title-two' />
                </div>
              </div>
              <div className='users-listing-box-lazy-buttons'>
                <ShimmerButton size="md" />
              </div>
            </div>
            <div className="lazy-users-listing-box-container">
              <div className="lazy-users-listing-box-photo-plus-title">
                <ShimmerCircularImage size={36} />
                <div className="lazy-users-listing-box-title-container">
                  <ShimmerTitle line={1} variant="secondary" className='users-listing-box-lazy-title-one' />
                  <ShimmerTitle line={1} variant="secondary" className='users-listing-box-lazy-title-two' />
                </div>
              </div>
              <div className='users-listing-box-lazy-buttons'>
                <ShimmerButton size="md" />
              </div>
            </div>
          </div>
        ) : view == "teamRequest" ? (
          <div className="lazy-team-requests-container">
            <ShimmerTitle line={1} variant="secondary" className="lazy-team-requests-search-bar" />
            <Row className="lazy-team-requests-row-one">
              <Col md={2} >
                <ShimmerTitle line={1} variant="secondary" className="lazy-team-requests-header-title-one" />
              </Col>
              <Col md={4} >
                <ShimmerTitle line={1} variant="secondary" className="lazy-team-requests-header-title-two" />
              </Col>
              <Col md={3} >
                <ShimmerTitle line={1} variant="secondary" className="lazy-team-requests-header-title-three" />
              </Col>
              <Col md={3} >
                <ShimmerTitle line={1} variant="secondary" className="lazy-team-requests-header-title-four" />
              </Col>
            </Row>
            {a && a.map((item, index) => {
              return (
                <Row key={index} className="lazy-team-requests-row-two">
                  <Col md={2} >
                    <ShimmerTitle line={1} variant="secondary" className="lazy-team-requests-data-one" />
                  </Col>
                  <Col md={4} >
                    <ShimmerTitle line={1} variant="secondary" className="lazy-team-requests-data-two" />
                  </Col>
                  <Col md={3} >
                    <ShimmerTitle line={1} variant="secondary" className="lazy-team-requests-data-three" />
                  </Col>
                  <Col md={3} >
                    <div className="lazy-team-requests-data-collective">
                      <ShimmerTitle line={1} variant="secondary" className="lazy-team-requests-data-four" />
                      <ShimmerTitle line={1} variant="secondary" className="lazy-team-requests-data-five" />
                    </div>
                  </Col>
                </Row>
              )
            })}
          </div>
        ) : view == "teamPeople" ? (
          <div className="lazy-team-people-container">
            <Row className="lazy-team-people-row-one">
              {[1, 2, 3].map((_, index) => (
                <Col key={index} md={4}>
                  <ShimmerTitle line={1} variant="secondary" className="lazy-team-people-row-one-data-one" />
                  <ShimmerThumbnail height={47} variant="secondary" className="lazy-team-people-row-one-data-two" />
                  <ShimmerTitle line={1} variant="secondary" className="lazy-team-people-row-one-data-three" />
                </Col>
              ))}
            </Row>
            <Row className="lazy-team-people-row-two">
              <Col md={10}>
                <ShimmerTitle line={1} variant="secondary" className="lazy-team-people-row-two-data-one" />
              </Col>
              <Col md={2}>
                <ShimmerTitle line={1} variant="secondary" className="lazy-team-people-row-two-data-two" />
              </Col>
            </Row>
            <Row className="lazy-team-people-row-three">
              <Col md={3}>
                <ShimmerTitle line={1} variant="secondary" className="lazy-team-people-row-three-data-one" />
              </Col>
              <Col md={3}>
                <ShimmerTitle line={1} variant="secondary" className="lazy-team-people-row-three-data-two" />
              </Col>
              <Col md={2}>
                <ShimmerTitle line={1} variant="secondary" className="lazy-team-people-row-three-data-three" />
              </Col>
              <Col md={2}>
                <ShimmerTitle line={1} variant="secondary" className="lazy-team-people-row-three-data-four" />
              </Col>
              <Col md={2}>
                <ShimmerTitle line={1} variant="secondary" className="lazy-team-people-row-three-data-five" />
              </Col>
            </Row>
            {a && a.map((item, index) => {
              return (
                <Row key={index} className="lazy-team-people-row-four">
                  <Col md={3} >
                    <ShimmerTitle line={1} variant="secondary" className="lazy-team-people-row-four-data-one" />
                  </Col>
                  <Col md={3} >
                    <ShimmerTitle line={1} variant="secondary" className="lazy-team-people-row-four-data-two" />
                  </Col>
                  <Col md={2} >
                    <ShimmerTitle line={1} variant="secondary" className="lazy-team-people-row-four-data-three" />
                  </Col>
                  <Col md={2}>
                    <ShimmerTitle line={1} variant="secondary" className="lazy-team-people-row-four-data-four" />
                  </Col>
                  <Col md={2}>
                    <ShimmerTitle line={1} variant="secondary" className="lazy-team-people-row-four-data-five" />
                  </Col>
                </Row>
              )
            })}
          </div>
        ) : view == "creditsHistory" ? (
          <div>
            <ShimmerThumbnail height={724} rounded />
          </div>
        ) : view == "creditsBlock" ? (
          <div>
            <ShimmerThumbnail height={228} rounded />
          </div>
        ) : view == "addCreditsWithoutBundle" ? (
          <div className="add-credits-lazy-container">
            <div className="add-credits-lazy-header">
              <ShimmerCircularImage className="add-credits-lazy-circle" size={23} />
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-header-line' />
              <ShimmerCircularImage className="add-credits-lazy-circle" size={20} />
              <ShimmerCircularImage className="add-credits-lazy-circle" size={23} />
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-header-line' />
              <ShimmerCircularImage className="add-credits-lazy-circle" size={20} />
              <ShimmerCircularImage className="add-credits-lazy-circle" size={23} />
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-header-line' />
            </div>
            <div className="add-credits-lazy-body">
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-body-line-one' />
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-body-line-two' />
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-body-line-three' />
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-body-line-four' />
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-body-line-five' />
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-body-line-six' />
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-body-line-seven' />
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-body-line-eight' />
            </div>
          </div>
        ) : view == "addCreditsWithBundle" ? (
          <div className="add-credits-lazy-container">
            <div className="add-credits-lazy-header">
              <ShimmerCircularImage className="add-credits-lazy-circle" size={23} />
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-header-line' />
              <ShimmerCircularImage className="add-credits-lazy-circle" size={20} />
              <ShimmerCircularImage className="add-credits-lazy-circle" size={23} />
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-header-line' />
              <ShimmerCircularImage className="add-credits-lazy-circle" size={20} />
              <ShimmerCircularImage className="add-credits-lazy-circle" size={23} />
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-header-line' />
            </div>
            <div className="add-credits-lazy-body">
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-body-line-one' />
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-body-line-two' />
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-body-line-three' />
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-body-line-four' />
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-body-line-five' />
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-body-line-six' />
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-body-line-nine' />
              <div className="add-credits-lazy-bundle-container">
                <div className="add-credits-lazy-bundle">
                  <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-body-line-ten' />
                  <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-body-line-ten' />
                  <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-body-line-ten' />
                </div>
                <div className="add-credits-lazy-bundle">
                  <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-body-line-ten' />
                  <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-body-line-ten' />
                </div>
              </div>
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-body-line-seven' />
              <ShimmerTitle line={1} variant="secondary" className='add-credits-lazy-body-line-eight' />
            </div>
          </div>
        ) : view == "creditSystemInfo" ? (
          <div className="credits-system-info-lazy-container">
            <div className="credits-system-info-lazy-header">
              <ShimmerCircularImage className="credits-system-info-lazy-circle" size={23} />
              <ShimmerTitle line={1} variant="secondary" className='credits-system-info-lazy-header-line' />
              <ShimmerCircularImage className="credits-system-info-lazy-circle" size={20} />
              <ShimmerCircularImage className="credits-system-info-lazy-circle" size={23} />
              <ShimmerTitle line={1} variant="secondary" className='credits-system-info-lazy-header-line' />
              <ShimmerCircularImage className="credits-system-info-lazy-circle" size={20} />
              <ShimmerCircularImage className="credits-system-info-lazy-circle" size={23} />
              <ShimmerTitle line={1} variant="secondary" className='credits-system-info-lazy-header-line' />
            </div>
            <div className="credits-system-info-lazy-body">
              <ShimmerTitle line={1} variant="secondary" className='credits-system-info-lazy-body-line-two' />
              <ShimmerTitle line={1} variant="secondary" className='credits-system-info-lazy-body-line-four' />
              <ShimmerTitle line={1} variant="secondary" className='credits-system-info-lazy-body-line-five' />
              <ShimmerTitle line={1} variant="secondary" className='credits-system-info-lazy-body-line-nine' />
              <div className="credits-system-info-lazy-bundle-container">
                <div className="credits-system-info-lazy-bundle">
                  <ShimmerTitle line={1} variant="secondary" className='credits-system-info-lazy-body-line-ten' />
                  <ShimmerTitle line={1} variant="secondary" className='credits-system-info-lazy-body-line-ten' />
                  <ShimmerTitle line={1} variant="secondary" className='credits-system-info-lazy-body-line-ten' />
                </div>
                <div className="credits-system-info-lazy-bundle">
                  <ShimmerTitle line={1} variant="secondary" className='credits-system-info-lazy-body-line-ten' />
                  <ShimmerTitle line={1} variant="secondary" className='credits-system-info-lazy-body-line-ten' />
                </div>
              </div>
              <ShimmerTitle line={1} variant="secondary" className='credits-system-info-lazy-body-line-seven' />
            </div>
          </div>
        ) : view == "creditSystemReview" ? (
          <div>
            <ShimmerThumbnail height={520} width={511} rounded />
          </div>
        ) : (
          (view = "videos-slide" ? (
            <ShimmerSimpleGallery card imageHeight={292} col={1} row={1} caption />
          ) : view == "demo" ?
            <ShimmerThumbnail className="lazyDesign_tableCol1" height={1000} rounded />
            : (
              <ShimmerSimpleGallery card imageHeight={292} col={2} row={2} caption />
            ))
        )}
    </>
  );
}